const enhanceImageURLs = (products, state) => {
    products.forEach(product => {
        product.variants.forEach(variant => {
            variant.images.forEach(image => {
                if (state.params.imageQuery) {
                    let connector = image.url.indexOf('?') > -1 ? '&' : '?'
                    image.url = `${image.url}${connector}${state.params.imageQuery}`
                }
            })
        })
    })
    return products
}

export class RestAdapter {
    async getItems(state, filterIds = []) {
        let products = await (await fetch(`${state.params.apiBaseUrl}?operation=getProducts&productIds=${filterIds.join(',')}`)).json()
        return enhanceImageURLs(products, state)
    }

    async search(state) {
        let products = await (await fetch(`${state.params.apiBaseUrl}?operation=getProducts&keyword=${state.searchText}`)).json()
        return { items: enhanceImageURLs(products, state) }
    }

    exportItem(item) {
        return item.id;
    }
}
